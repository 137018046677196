var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('responsive-menu',{ref:"menu",attrs:{"title":_vm.selectedItemName,"opcoes":_vm.menuOptions},on:{"click:item":function (action) { return _vm.$emit(("click:" + action), _vm.selectedItem); }}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
    disableItemsPerPage: true,
    itemsPerPageOptions: [_vm.itemsPerPage],
  },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event},"click:row":_vm.onClickRow,"contextmenu:row":function($event){$event.preventDefault();return _vm.showMenu.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
  var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.edit",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.showMenu($event, item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
  var items = ref.items;
  var isSelected = ref.isSelected;
  var select = ref.select;
return [(items.length === 0)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v("Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.")])])],1):_vm._e(),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item",on:{"click":function($event){return _vm.showMenu($event, item)}}},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(item.id)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(((item.descricaoGrupo) + " / " + (item.descricao)))}}),_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"small":"","input-value":"false","outlined":""}},[_vm._v(_vm._s((item.flgValor==="S" ? "" : "Não ") + "Modifica Valor"))])],1),_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"small":"","input-value":"false","outlined":""}},[_vm._v(_vm._s((item.flgData==="S" ? "" : "Não ") + "Modifica Data"))])],1)],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)}),1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }